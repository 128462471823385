<template>
<b-container fluid>
  <bo-page-title />

  <b-card v-if="isList" no-body>
    <b-card-header>
      <b-row>
        <b-col lg="5">
          <h5 class="card-title">{{ pageTitle }}</h5>
        </b-col>
        <b-col lg="3">
          <b-form-group>
            <v-select placeholer="Select Status" label="label" :reduce="v => v.value" @input="doFilter()" v-model="filter.status" :options="statusFilters"></v-select>
          </b-form-group>
        </b-col>
        <b-col lg="4">
          <SearchInput :value.sync="filter.search" @search="doFilter" />
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body class="p-0">
      <b-table
        :fields="fields"
        :items="dataList||[]"
        :primary-key="idKey"
        :busy="!dataList"
        responsive
        show-empty
        striped
      >
        <template #empty>
          <div class="text-center">
            <b-img class="mb-2" width="90" fluid src="/assets/images/no-data.png" />
            <h4 align="center"><span v-if="Object.keys($route.query).length">No search results found</span><span v-else>No {{ pageTitle }} listed yet</span></h4>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mr-3 text-info"></b-spinner>
            <strong class="text-info">Loading...</strong>
          </div>
        </template>
        <template #cell(number)="v">
          {{(data.per_page*(data.current_page-1))+v.index+1}}
        </template>
        <template #cell(ta_feedback)="data">
          {{ data.value || '-' }}
        </template>
        <template #cell(ta_date)="data">
          <b-badge variant="danger" v-if="data.item.ta_status == 'NS' && isLateDate(data.item)">Late</b-badge>
          <p>{{ data.value | moment("LL") }}</p>

          <div>
            <sub>Start : <strong>{{ formatTime(data.item.ta_start_time) }}</strong></sub> <br>
            <sub>End   : <strong>{{ formatTime(data.item.ta_end_time) }}</strong></sub>
          </div>
        </template>
        <template #cell(ta_status)="data">
          <b-badge variant="info" v-if="data.value == 'NS'">Not Started</b-badge>
          <b-badge variant="warning" v-else-if="data.value == 'HS'">Running</b-badge>
          <template v-else>
            <b-badge variant="success">Finished</b-badge>
            
            <br>
            
            <b-badge class="mt-2" variant="warning" v-if="data.item.ta_confirm_admin != 'Y'">Not Confirmed</b-badge>
            <b-badge class="mt-2" variant="success" v-else>Confirmed</b-badge>
          </template>
        </template>
        <template
          #cell(action)="data"
        >
          <router-link
            v-if="data.item.ta_status != 'F' && moduleRole('edit')"
            v-b-tooltip.hover
            :title="data.item.ta_status != 'F' ? 'Edit' : 'Detail'"
            class="icon_action"
            :to="{name: $route.name, params: {pageSlug: data.item[idKey]}}"
          >
            <i :class="{'ti-marker-alt': data.item.ta_status != 'F', 'ti-eye': data.item.ta_status == 'F'}"></i>
          </router-link>

          <router-link
            v-if="data.item.ta_status == 'F'"
            v-b-tooltip.hover
            :title="data.item.ta_status != 'F' ? 'Edit' : 'Detail'"
            class="icon_action"
            :to="{name: $route.name, params: {pageSlug: data.item[idKey]}}"
          >
            <i :class="{'ti-marker-alt': data.item.ta_status != 'F', 'ti-eye': data.item.ta_status == 'F'}"></i>
          </router-link>

          <a
            v-if="moduleRole('delete')"
            v-b-tooltip.hover
            title="Delete"
            class="icon_action"
            @click="doDelete(data.index, data.item)"
            style="cursor:pointer;"
          >
            <i class="ti-trash"></i>
          </a>

          <a
            v-if="data.item.ta_status != 'F'"
            v-b-tooltip.hover
            title="Update Time"
            class="icon_action"
            @click="openUpdateTime(data.item)"
            style="cursor:pointer;"
          >
            <i class="ti-timer"></i>
          </a>

          <a
            v-if="data.item.ta_status != 'F'"
            v-b-tooltip.hover
            title="Show QR Code"
            class="icon_action"
            @click="openQRCode(data.item)"
            style="cursor:pointer;"
          >
            <i class="fas fa-qrcode"></i>
          </a>

          <a
            v-b-tooltip.hover
            title="Activity Log"
            class="icon_action"
            @click="openActivityLog(data.item)"
            style="cursor:pointer;"
          >
            <i class="ti-receipt"></i>
          </a>         

          <a
            v-if="(data.item.ta_status == 'F' || data.item.ta_status == 'HS') && data.item.ta_confirm_admin != 'Y'"
            v-b-tooltip.hover
            :title="data.item.ta_status == 'F' ? 'Confirm Assignment' : 'Finish Work'"
            class="icon_action"
            @click="confirmAssignment(data.item)"
            style="cursor:pointer;"
          >
            <i class="ti-check"></i>
          </a>

        </template>
      </b-table>
    </b-card-body>
    <b-card-footer>
      <template v-if="(dataList||[]).length&&data.total>10">
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <label for="perPage" class="mb-0 mr-2">Show</label>
              <b-form-select
                size="sm"
                id="perPage"
                class="w-auto mx-50"
                v-model="perPage"
                :options="Config.dataShownOptions"
              />
            <label for="perPage" class="mb-0 ml-2">items per page</label>
          </div>
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </div>
      </template>
    </b-card-footer>

    <b-modal
      id="activityLog"
      title="Activity Log"
      centered
      no-close-on-backdrop
      size="lg"
    >
      <b-row>
        <b-col md=4>
          <b-form-group label="Assignment Date" label-for="assignmentDate" label-class="mb-1">
            <span class="font-semibold">{{ dataModal.ta_date | moment("LL") }}</span>
          </b-form-group>          

          <b-form-group label="Start Time" label-for="assignmentDate" label-class="mb-1">
            <span class="font-semibold">{{ dataModal.ta_start_time }}</span>
          </b-form-group>         

          <b-form-group label="" label-class="mb-1">
            <i class="ti-arrow-down" style="display: block; transform: translateX(18px)"></i>
          </b-form-group>         

          <b-form-group label="End Time" label-for="assignmentDate" label-class="mb-1">
            <span class="font-semibold">{{ dataModal.ta_end_time }}</span>
          </b-form-group>       

          <b-form-group label="Duration time" label-for="assignmentDate" label-class="mb-1" v-if="dataModal.ta_status == 'F'">
            <span class="font-semibold">{{ dataModal.finish_time }}</span>
          </b-form-group>          
        </b-col>
        <b-col md=8>
          <b-table
            striped
            :items="fieldsLog"
          >

          </b-table>
        </b-col>
      </b-row>
        <template #modal-footer>
        <b-button size="md" variant="info" @click="() => $bvModal.hide('activityLog')">Close</b-button>
      </template>
    </b-modal>

    <b-modal
      id="QRCode"
      title="QR Code"
      centered
      no-close-on-backdrop
      size="lg"
    >
      <b-row>
       <b-col md="12">
        <div class="text-center">
          <b-img :src="uploader(dataModal.qr_code)" fluid alt="QR Code"></b-img>
        </div>
       </b-col>
      </b-row>
        <template #modal-footer>
        <b-button size="md" variant="info" @click="() => $bvModal.hide('QRCode')">Close</b-button>
      </template>
    </b-modal>

    <b-modal
      id="updateTime"
      :title="'Edit Time'"
      centered
      no-close-on-backdrop
    >
      <validation-observer v-slot="{ handleSubmit }" ref="VFormUpdateTime">
        <b-form @submit.prevent="handleSubmit(updateTime())">
          <b-row>
            <b-col md="6">
              <b-form-group>
                <label>Start Time <span class="text-danger mr5">*</span></label>
                <date-picker value-type="format" type="time" format="HH:mm" prefix-class="crm" v-model="dataModal.ta_start_time" v-b-tooltip.hover="'Start Time'" @change="setEndTimeNull('dataModal')" />
                <VValidate name="Start Time" v-model="dataModal.ta_start_time" :rules="{required: true}" />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <label>End Time <span class="text-danger mr5">*</span></label>
                <date-picker value-type="format" type="time" format="HH:mm" prefix-class="crm" v-model="dataModal.ta_end_time" v-b-tooltip.hover="'End Time'" :disabled-time="notBeforeStart" :disabled="!dataModal.ta_start_time" />
                <VValidate name="End Time" v-model="dataModal.ta_end_time" :rules="{required: true}" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button size="md" variant="transparent" @click="closeModal('updateTime')">Cancel</b-button>
        <b-button size="md" variant="info" @click="updateTime()">Update Time</b-button>
      </template>
    </b-modal>
  </b-card>

  <template v-else>
    <Form :row.sync="row" :Config="Config" :mrValidation="mrValidation" :mrPic="mrPic" :mrClient="mrClient" />
  </template>
</b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import Form from './Form.vue'
import Gen from '@/libs/Gen'

export default {
  extends: GlobalVue,
  components:{Form},
  data() {
    return {
      idKey:'ta_id',
      statusKey:'',
      statusFilters: [
        { label: 'All Status', value: '' },
        { label: 'Not Started', value: 'NS' },
        { label: 'Running', value: 'HS' },
        { label: 'Finish', value: 'F' },
      ],
      mrValidation: {},
      dataModal: {},
      mrPic: [],
      mrClient: [],
      fields: [
        {
          key: 'number', label: '#', 
        },
        {
          key: 'pic', label: 'PIC',
        },
        {
          key: 'ta_client_name', label: 'Client Name',
        },
        {
          key: 'ta_client_address', label: 'Client Address',
        },
        {
          key: 'ta_feedback', label: 'Feedback',
        },
        {
          key: 'ta_date', label: 'Date',
        },
        {
          key: 'ta_status', label: 'Status',
        },
        {
          key: 'action', 
        },
      ],
      fieldsLog: [],
      mrMenuRel:[]
    }
  },
  mounted(){
    this.apiGet()
    this.filter.status = this.$route.query.status || ''
  },
  methods: {
    notBeforeStart(date){
      return date.getHours() < +this.dataModal.ta_start_time.split(":")[0] || date.getMinutes() < +this.dataModal.ta_start_time.split(":")[1]
    },
    notBeforeToday(date){
      return date < new Date(new Date().setHours(0, 0, 0, 0))
    },
    isLateDate(data){      
      if(!data) return false
      const schedule = this.$moment(data.ta_date, 'DD-MM-YYYY')
      const now = this.$moment(this.$moment().format('YYYY-MM-DD'), 'DD-MM-YYYY')

      const isSameDay = this.$moment(now).isSame(schedule)
      const isAfter = this.$moment().format('YYYY-MM-DD') > data.ta_date      
      
      const timeNow = new Date().getHours() + ':' + new Date().getMinutes() + ":00"            

      if((isSameDay && timeNow >= data.ta_end_time) || isAfter){
        return true
      } else {
        return false
      }
    },
    formatTime(time){
      if(!time) return "-"
      const timeArr = time.split(":")
      timeArr.pop()
    
      return timeArr.join(":")
    },
    openActivityLog(data){
      this.$set(this, 'fieldsLog', [
        {
          "#" : 1, activity: 'Start Work', time: data.ta_start_work ? this.$moment(data.ta_start_work).format("LLL") : '-', 
        },
        {
          "#" : 2, activity: 'Finish Work', time: data.ta_finish_work ? this.$moment(data.ta_finish_work).format("LLL") : '-',
        },
        {
          "#" : 3, activity: 'Confirmed by Admin', time: data.ta_confirm_admin_date ? this.$moment(data.ta_confirm_admin_date).format("LLL") : '-', 
        },
      ])
      this.$set(this, 'dataModal', data)
      this.$bvModal.show("activityLog")      
    },
    openUpdateTime(data){
      this.$set(this, 'dataModal', data)
      this.$bvModal.show("updateTime")      
    },
    openQRCode(data){
      this.$set(this, 'dataModal', {...data})
      this.$bvModal.show("QRCode")
    },
    confirmAssignment(data){      
      this.$swal({
        title: data.ta_status == 'F' ? "Are you sure to confirm this assignment?" : "Are you sure to finish work this assignment?",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes, Sure',
        icon: 'question',
      }).then(ok => {
        if(ok.isConfirmed){
          Gen.apiRest(
            '/do/' + this.modulePage,
            { data: { type: "confirm-assignment", id: data.ta_id } },
            'POST'
          ).then(res => {
            if(res){
              this.$swal({
                title: data.ta_status == 'F' ? res.data.message : "Finish work sucessfully.",
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.apiGet()                
              })
            }
          })
        }
      })
    },
    updateTime(){
      this.$refs.VFormUpdateTime.validate().then(success => {
        if(!success) return 

        this.$swal({
          title: 'Update Time Assignment...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        Gen.apiRest(
          '/do/' + this.modulePage,
          { data: {type: 'update-time', ...this.dataModal} },
          'POST'
        ).then(res => {
          if(res){
              this.$swal({
                title: res.data.message,
                timer: 1500,
                icon: 'success',
              }).then(() => {
                this.apiGet()
                this.$bvModal.hide('updateTime')
              })
          }
        }).catch(err => {
            this.loadingOverlay = false 
            if(err){
              return this.$swal({
                title: err.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
        })        
      })
    },
    closeModal(id, resetRow = true){
      this.$bvModal.hide(id)
      if(resetRow){
        this.$set(this, 'row', {})
      }
    },
    setEndTimeNull(dataName){
      this.$set(this[dataName], 'ta_end_time', null)
    }
  },
  watch:{
    $route(){
      this.apiGet()
    },
    'filter'(){
      if('status' in this.filter == false){
        this.$set(this.filter, 'status', '')
      }
    }
  }
}
</script>