<template>
  <div>
    <b-overlay :show="loadingMap">
      <div id="map"></div>
    </b-overlay>
  </div>
</template>
<script>
/* eslint-disable */
import GlobalVue from '../libs/Global.vue'
import { Loader } from '@googlemaps/js-api-loader'

export default {
	extends: GlobalVue,
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          click: false,
          draggable: false
        }
      }
    },
  },
  data(){
    return { 
      loadingMap: true,
      map: false,
      marker: false,
      geocoder: false,
      loader: false,
      zoom: 15
    }
  },
  created(){
    this.loader = new Loader({
      apiKey: process.env.VUE_APP_GMAPS_API_KEY,
      version: "weekly",
      libraries: ["places"]
    })
  },
	mounted() {
    this.initMap(this.$props.config)
	},
  methods: {
    initMap(conf) {
      this.loader.load().then((google) => {
        setTimeout(() => {         
          if(conf.geocoder) this.geocoder = new google.maps.Geocoder()                
          this.map = new google.maps.Map(document.getElementById("map"), {
            center: { lat: +conf.lngLat[1]||-6.200000, lng: +conf.lngLat[0]||106.816666 },
            zoom: this.zoom,
          })
          
          this.map.addListener("click", (event) => {
            this.setLngLatMarker({lat: event.latLng.lat(), lng: event.latLng.lng()})
          })
    
          if(conf.lngLat){
            this.marker =  new google.maps.Marker({
              position: new google.maps.LatLng(+conf.lngLat[1], +conf.lngLat[0]),
              draggable: true,
              animation: google.maps.Animation.DROP,
            })
            google.maps.event.trigger(this.map, 'resize')
            this.marker.setMap(this.map) // add new marker
          }
  
          if(conf.draggable){
            google.maps.event.addListener(this.marker, 'dragend', (dataMarker) => {
              const latLngDragMarker = {lat: +dataMarker.latLng.lat(), lng: +dataMarker.latLng.lng()}
              this.$emit('click:setLngLat', latLngDragMarker)
  
              // this.setCenterMap(latLngDragMarker)
            }) // add event drag marker
          }
  
          this.loadingMap = false
        }, 800)
      })
    },
    setLngLatMarker(lngLat, zoom=false){ 
      const latLng = new google.maps.LatLng(lngLat.lat, lngLat.lng)
      if(this.marker){
        this.marker.setPosition(latLng) // set position marker
      } else {
        this.marker =  new google.maps.Marker({
          position: latLng,
          draggable: true,
          animation: google.maps.Animation.DROP,
        })

        this.marker.setMap(this.map) // add new marker
      }

      this.$emit('click:setLngLat', lngLat)

      if(zoom)
        this.setCenterMap(latLng)
    },
    setCenterMap(lngLat){
      this.map.panTo(lngLat)
      this.map.setZoom(this.zoom)
    },
    geocodeAddress(location, setMarker=true){
      this.geocoder.geocode({ address: location }, (result, status) => {
        if(status == 'OK'){
          const data = result[0]

          const address = {
            address: data.formatted_address,
            lat: data.geometry.location.lat(),
            lng: data.geometry.location.lng()
          }

          if(setMarker){
            this.$emit('click:setLngLat', { lat: address.lat, lng: address.lng })          
            this.setLngLatMarker({ lat: address.lat, lng: address.lng }, true)
          } else {
            this.setCenterMap({ lat: address.lat, lng: address.lng })
          }
        }
      })
    },
  },
}
</script>