<template>
    <b-card no-body v-if="row.ta_status != 'F'">
      <validation-observer v-slot="{ handleSubmit }" ref="VForm">
        <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
          <b-card-header>
            <b-row>
              <b-col lg="8">
                <h5 class="card-title">{{ $parent.isAdd?'Add':'Edit' }} {{ $parent.pageTitle }}</h5>
              </b-col>
            </b-row>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col lg="8">
                <b-row>
                  <b-col lg="6">
                    <b-form-group>
                      <label>PIC <span class="text-danger mr5">*</span></label>
                      <v-select v-model="row.ta_pic" placeholder="Select PIC" :options="mrPic" :reduce="v => v.value">
                      </v-select>
                      <VValidate name="PIC" v-model="row.ta_pic" :rules="mrValidation.ta_pic" />
                    </b-form-group>
                  </b-col>          
                  <b-col lg="6">
                    <b-form-group>
                      <label>Client <span class="text-danger mr5">*</span></label>
                      <v-select v-model="row.ta_client" placeholder="Select Client" :options="mrClient" :reduce="v => v.value">
                        <template v-slot:option="option">                          
                          <p class="w-100 mb-0" v-b-tooltip.hover :title="option.address">{{ option.label }}</p>
                        </template>
                      </v-select>
                      <VValidate name="Client" v-model="row.ta_client" :rules="mrValidation.ta_client" />
                    </b-form-group>
                  </b-col>

                  <b-col lg="12">
                    <b-form-group>
                      <label>Address <span class="text-danger mr5">*</span></label>
                      <b-form-textarea rows="5" v-model="row.ta_client_address" placeholder="e.g Address"></b-form-textarea>
                      <VValidate name="Address" v-model="row.ta_client_address" :rules="{min: 5, max: 256, required: true}" />

                      <div class="d-flex align-items-center justify-content-between mt-3">
                        <small>*Ketikkan "Kec. (Nama Kecamatan), (Nama Kota/Provinsi)." Untuk memusatkan peta sesuai dengan kecamatan yang diinginkan.</small>


                        <b-button variant="outline-info" @click="centeringMap()" :disabled="!row.ta_client_address">Pusatkan Peta</b-button>
                      </div>
                    </b-form-group>
                  </b-col>

                  <b-col lg="12">
                    <b-row>
                      <b-col lg="8">
                        <GoogleMaps
                          :config="configMap" 
                          @click:setLngLat="setLngLat(...arguments)"
                          ref="google"
                        />
                      </b-col>
                      <b-col lg="4">
                        <b-form-group>
                          <label>Latitude</label>
                          <b-form-input type="text" v-model="row.ta_latitude" placeholder="e.g Latitude" :readonly="true"></b-form-input>
                          <VValidate name="Latitude" v-model="row.ta_latitude" :rules="{min: 5, max: 256}"/>
                        </b-form-group>

                        <b-form-group>
                          <label>Longitude</label>
                          <b-form-input type="text" v-model="row.ta_longitude" placeholder="e.g Longitude" :readonly="true"></b-form-input>
                          <VValidate name="Longitude" v-model="row.ta_longitude" :rules="{min: 5, max: 256}" />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>

              <b-col lg="4">
                <b-col lg="12">
                  <b-form-group>
                    <label>Assignment Date <span class="text-danger mr5">*</span></label>
                    <date-picker value-type="format" format="YYYY-MM-DD" prefix-class="crm" v-model="row.ta_date" v-b-tooltip.hover="'Date'" :disabled-date="$parent.notBeforeToday" />
                    <VValidate name="Assignment Date" v-model="row.ta_date" :rules="{required: true}" />
                  </b-form-group>
                </b-col>
                <b-col lg="12">
                  <b-row>
                    <b-col lg="6">
                      <b-form-group>
                        <label>Start Time <span class="text-danger mr5">*</span></label>
                        <date-picker value-type="format" type="time" format="HH:mm" prefix-class="crm" v-model="row.ta_start_time" v-b-tooltip.hover="'Start Time'" @change="$parent.setEndTimeNull('row')" />
                        <VValidate name="Start Time" v-model="row.ta_start_time" :rules="{required: true}" />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6">
                      <b-form-group>
                        <label>End Time <span class="text-danger mr5">*</span></label>
                        <date-picker value-type="format" type="time" format="HH:mm" prefix-class="crm" v-model="row.ta_end_time" v-b-tooltip.hover="'End Time'" :disabled-time="notBeforeStart" :disabled="!row.ta_start_time" />
                        <VValidate name="End Time" v-model="row.ta_end_time" :rules="{required: true}" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col lg="12">
                  <b-form-group>
                    <label>Purpose <span class="text-danger mr5">*</span></label>
                    <b-form-textarea rows="5" v-model="row.ta_reason" placeholder="e.g Purpose"></b-form-textarea>
                    <VValidate name="Purpose" v-model="row.ta_reason" :rules="{min: 5, max: 256, required: true}" />
                  </b-form-group>
                </b-col>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>
            <b-row>
              <b-col lg="12" class="text-right">
                <button @click="$router.back()" type="button" class="btn btn-rounded btn-default mr-3">Cancel</button>
                <b-button type="submit" variant="info" class="btn-rounded">Submit</b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card no-body v-else>
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">Detail {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="5" class="border-right">
            <b-row>
              <b-col lg="12">
                <div class="detail-info">
                  <span>PIC</span>
                  <h4>{{ row.pic }}</h4>
                </div>
              </b-col>

              <b-col lg="12">
                <div class="detail-info">
                  <span>Assignment Date</span>
                  <h4>{{ row.ta_date | moment("LL") }}</h4>
                </div>
              </b-col>

              <b-col lg="12">
                <div class="detail-info">
                  <span>Start Time</span>
                  <h4>{{ row.ta_start_time }}</h4>
                </div>
              </b-col>

              <b-col lg="12">
                <div class="detail-info">
                  <i class="ti-arrow-down" style="display: block; transform: translateX(18px)"></i>
                </div>
              </b-col>

              <b-col lg="12">
                <div class="detail-info">
                  <span>End Time</span>
                  <h4>{{ row.ta_end_time }}</h4>
                </div>
              </b-col>              
            </b-row>
          </b-col>
          
          <b-col lg="5" class="border-right">
            <b-row>
              <b-col lg="12">
                <div class="detail-info">
                  <span>Client Name</span>
                  <h4>{{ row.client_name }}</h4>
                </div>
              </b-col>

              <b-col lg="12">
                <div class="detail-info">
                  <span>Client Address</span>
                  <h4>{{ row.ta_client_address }}</h4>
                </div>
              </b-col>  

              <b-col lg="12">
                <div class="detail-info">
                  <span>Purpose</span>
                  <h4>{{ row.ta_reason }}</h4>
                </div>
              </b-col>

              <b-col lg="12" v-if="row.ta_feedback">
                <div class="detail-info">
                  <span>Feedback</span>
                  <h4>{{ row.ta_feedback }}</h4>
                </div>
              </b-col>
            </b-row>
          </b-col>

        </b-row>
      </b-card-body>
    </b-card>
</template>
<style scoped>
  .mapouter{position:relative;text-align:right;width:100%;height:400px;}.gmap_canvas {overflow:hidden;background:none!important;width:100%;height:400px;}.gmap_iframe {width:100%!important;height:400px!important;}
</style>
<script>
 import GoogleMaps from '@/components/GoogleMaps'

 export default {
    components: {
      GoogleMaps
    },
    props: {
      row: Object,
      mrValidation: [Array, Object],      
      mrClient: [Array, Object],
      mrPic: [Array, Object],
      Config: Object,
      isLoadingGenerate: Boolean
    },
    data(){
      return {
        positionOptions: [],
        disabledGenerate: false,
        configMap: {
          click: true,
          draggable: true,
          geocoder: true,
          lngLat: []
        },
      }
    },
    mounted(){
      setTimeout(() => { 
        this.$set(this.configMap, 'lngLat', [this.row.ta_longitude, this.row.ta_latitude])        
      }, 700)
    },
    methods: {
      doSubmitForm() {
        this.$emit('click:doSubmit', 'VForm', this.$refs)
      },
      notBeforeStart(date){
        return date.getHours() < +this.row.ta_start_time.split(":")[0]
      },
      setLngLat(lngLat){
        const latitude = lngLat[1] || lngLat.lat
        const longitude = lngLat[0] || lngLat.lng

        this.$set(this.row, 'ta_latitude', latitude)
        this.$set(this.row, 'ta_longitude', longitude)        
      },
      centeringMap(){
        const address = this.row.ta_client_address
        if(address.match(/Kec.*?,/g)){
          const districts = address.match(/Kec.*?,/g)[0].replace(",", "")

          this.$refs.google.geocodeAddress(districts, false)
        }
      }
    },
    watch: {
      'row.ta_client'(v){
        const selectedClient = this.mrClient.find(x => x.value == v)
        
        this.$set(this.row, 'ta_client_address', selectedClient?.address)
      }
    }
 }
</script>
